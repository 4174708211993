export const englishTexts = {
  name: 'Nome',
  value: 'Valor',
  amount: 'Quantidade',
  show: 'Show',
  isActive: 'Ativo',
  productId: 'Produto',
  WAITER: 'Garçom',
  startValue: 'Valor inicial',
};
